import React from 'react';
import { BsX, BsCircleHalf, BsHouse, BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";

import style from './NavLinks.module.css'

export default function NavLinks(props){

    return (
        <div style={{ flex: "1 1 50%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <Link to="/">
                <div className={style.navIcon}>
                    <BsHouse size="1.2em" />
                </div>
            </Link>
            {/* <div className={style.navIcon}>
                <BsCircleHalf size="1.2em" />
            </div> */}
            {props.slideInSearch && <div className={style.navIcon} onClick={() => props.toggleSlideInSearch(false)}>
                <BsSearch size="1.2em" /> 
            </div>}
            {!props.slideInSearch && <div className={style.navIcon} onClick={() => props.toggleSlideInSearch(true)}>
                <BsX size="1.2em" />
            </div>}
        </div>
    );
}