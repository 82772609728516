import React from "react";
import { Helmet } from "react-helmet";

import style from "./HymnContent.module.css";

const HymnContent = (props) => {
  const ShowHymnHeader = (props) => {
    return <div className={style.hymnHeader}>{props.title}</div>;
  };
  const nl2br = (str) => {
    return str.trim().replace(/(?:\r\n|\r|\n)/g, "\n");
  };
  const ShowHymnEntries = (props) => {
    //Check for Emtry types(Antiphon,etc) and render
    if (props.entry === undefined || props.entry === "1") {
      return (
        <div style={{ marginTop: "4rem" }} className={style.hymnStanza}>
          <div className={style.hymnStanzaParagraph}>{props.value}</div>
        </div>
      );
    } else {
      return (
        <div className={style.hymnStanza}>
          <div className={style.hymnStanzaNumber}>{props.entry}</div>
          <div className={style.hymnStanzaParagraph}>{`${nl2br(props.value)}`}</div>
        </div>
      );
    }
  };

  const selectedHymnArray = props.hymnData.filter((curr) => curr.hymnNumber === Number(props.match.params.hymnNumber));
  const selectedHymn = selectedHymnArray[0];
  const loadingSpinner = props.loadingSpinner;
  console.log("this props match params", props.match.params);
  if (selectedHymn !== undefined) {
    const { hymnNumber } = selectedHymn;
    const { hymnTitle } = selectedHymn;
    const hymnEntries = [];
    console.log("selectedHymn", selectedHymn);

    for (const val in selectedHymn) {
      if (val.includes("entry")) {
        hymnEntries.push(selectedHymn[val]);
      } else if (val.includes("lastEntry")) {
        hymnEntries.push(selectedHymn.lastEntry);
      }
    }

    console.log("hymnEntries", hymnEntries);

    return (
      <>
        <Helmet>
          <title>
            Hymn {hymnNumber.toString()} - {hymnTitle}{" "}
          </title>
          <meta name="description" content={`${hymnEntries[0].value.trim().replace(/(?:\r\n|\r|\n)/g, "\n")}`} />
          <meta
            name="keywords"
            content={`Hymn ${hymnNumber.toString()}, ${hymnTitle}, ${hymnEntries[0].value.trim().replace(/(?:\r\n|\r|\n)/g, "\n")}`}
          />
        </Helmet>
        <div className="content" style={{ flexBasis: "100%", background: "rgb(25, 25, 25)", borderRadius: "5px", alignSelf: "flex-start" }}>
          <ShowHymnHeader number={hymnNumber} title={hymnTitle} />
          {hymnEntries.map((curr) => (
            <ShowHymnEntries key={curr.order} entry={curr.entry} value={curr.value} />
          ))}
        </div>
      </>
    );
  } else {
    return (
      loadingSpinner && (
        <div style={{ paddingTop: ".5rem", textAlign: "center" }}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="150px"
            height="300px"
            viewBox="0 0 200 300"
            style={{ enableBackground: "new 0 0 500 500" }}>
            <rect x="0" y="100" width="40" height="100" fill="#888" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
              <animate attributeName="height" attributeType="XML" values="100; 200; 100" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
              <animate attributeName="y" attributeType="XML" values="100; 50; 100" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
            </rect>
            <rect x="80" y="100" width="40" height="100" fill="#888" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
              <animate attributeName="height" attributeType="XML" values="100; 200; 100" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
              <animate attributeName="y" attributeType="XML" values="100; 50; 100" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
            </rect>
            <rect x="160" y="100" width="40" height="100" fill="#888" opacity="0.2">
              <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
              <animate attributeName="height" attributeType="XML" values="100; 200; 100" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
              <animate attributeName="y" attributeType="XML" values="100; 50; 100" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
            </rect>
          </svg>
        </div>
      )
    ); // No hymn was returned
  }
};

export default HymnContent;
