import React from "react";
import { BsForward } from "react-icons/bs";
import { Scrollbar } from "react-scrollbars-custom";
import { Link } from "react-router-dom";

import style from "./SearchInterface.module.css";

export default function SearchInterface(props) {
  const hymnsBasedOnSearch = props.hymnData;
  const loadingSpinner = props.loadingSpinner;
  const setHymnNumber = (param) => {
    props.setNumber(param);
  };

  const hymnSelected = (num) => {
    setHymnNumber(num);
    console.log("width", window.outerWidth);
    if (window.outerWidth < 768) {
      props.toggleSlideInSearch(true);
    }
  };

  if (props.openSI === true) {
    return (
      // if (hymnsBasedOnSearch.length > 0) {
      <div className={style.searchOverlay}>
        {hymnsBasedOnSearch.length > 0 && (
          <Scrollbar noScrollX={true} translateContentSizeYToHolder={true} style={{ maxHeight: "calc(100vh - 40vh)" }}>
            {hymnsBasedOnSearch.map((val) => {
              return (
                <Link to={`/hymn/${val.hymnNumber}`} key={val.hymnNumber} onClick={() => hymnSelected(val.hymnNumber)}>
                  <div className={style.hymnSearchItem}>
                    <h4>{val.hymnNumber}</h4>
                    <h5 style={{ flex: "1 1 100%", paddingLeft: "1rem", textAlign: "left" }}>{val.hymnTitle}</h5>
                    <div className="nav-icon" style={{ background: "#111", padding: "12px 13px 8px 13px", borderRadius: "50%", margin: "0 5px" }}>
                      <BsForward size="1.2em" />
                    </div>
                  </div>
                </Link>
              );
            })}
          </Scrollbar>
        )}
        {loadingSpinner && (
          <Scrollbar noScrollX={true} translateContentSizeYToHolder={true} style={{ maxHeight: "calc(100vh - 20vh)" }}>
            <div style={{ paddingTop: ".5rem", textAlign: "center" }}>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="30px"
                viewBox="0 0 24 30"
                style={{ enableBackground: "new 0 0 50 50" }}>
                <rect x="0" y="10" width="4" height="10" fill="#888" opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite"></animate>
                </rect>
                <rect x="8" y="10" width="4" height="10" fill="#888" opacity="0.2">
                  <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="0.2; 1; .2"
                    begin="0.15s"
                    dur="0.6s"
                    repeatCount="indefinite"></animate>
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate>
                </rect>
                <rect x="16" y="10" width="4" height="10" fill="#888" opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate>
                </rect>
              </svg>
            </div>
            <div className={style.hymnSearchItem} style={{ justifyContent: "center", paddingBottom: "18px", paddingTop: 0 }}>
              <em style={{ padding: "10px", textAlign: "center" }}>
                Clear your throat,
                <br /> we are fetching all hymns ...
              </em>
            </div>
          </Scrollbar>
        )}
      </div>
    );
  } else {
    return null;
  }
}
