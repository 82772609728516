import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import { BsX, BsXCircleFill, BsHouse, BsSearch } from "react-icons/bs";
import HymnContent from "./components/HymnContent";
import SearchInterface from "./components/SearchInterface";
import Header from "./components/Header";

import "./App.css";
import style from "./components/Header.module.css";

export default function App() {
  const [openSearchInterface, showHideSearchInterface] = useState(true);
  const [hymnData, setHymnData] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [slideInSearch, toggleSlideInSearch] = useState(window.outerWidth <= 768 ? true : false);
  const [searchText, setSearchText] = useState("");
  const [hymnNumber, setNumber] = useState("");
  const newHymnData = hymnData.filter(
    (curr) =>
      curr.hymnTitle.toLowerCase().includes(searchText.toLowerCase()) || curr.hymnNumber.toString().toLowerCase().includes(searchText.toLowerCase())
  );

  const cancelSearch = () => {
    // showHideSearchInterface(false)
    setSearchText("");
  };

  // const toggleSlideInSearch = ()=>{
  //   setSlideInSearch(!slideInSearch);
  // }

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axios(`https://camhymn.divynemedia.com/api/hymns`);
      const json = response.data;

      if (response.status !== 200) {
        throw Error(response.statusText);
      } else if (response.status === 200) {
        console.log("Data loaded", response.statusText);
        setLoadingSpinner(false);
        setHymnData(json);
      }
      // ...
    }
    try {
      fetchData();
    } catch (error) {}
  }, []);

  return (
    <HashRouter>
      <div className="App" style={{ display: "flex", minHeight: "100vh", color: "#ccc", fontSize: "1.2rem" }}>
        <div className={`${slideInSearch ? style.contractMainView : style.mainView}`}>
          <Header
            openSI={openSearchInterface}
            /*showHideSearchInterface={showHideSearchInterface}*/ hymnData={hymnData}
            toggleSlideInSearch={toggleSlideInSearch}
            slideInSearch={slideInSearch}
          />
          <div
            /*onClick={() => () => showHideSearchInterface(false)}*/ className="contentWrapper"
            style={{ flexBasis: "100%", padding: "1rem", display: "flex", justifyContent: "center", paddingBottom: "10rem", alignItems: "center" }}>
            <Switch>
              <Route path="/" exact>
                <Helmet>
                  <title>The Hymnal</title>
                  <meta
                    name="description"
                    content="Welcome to The Hymnal. Browse over 349 christian hymns. You can also search for hymns by their hymn number & hymn title."
                  />
                  <meta name="keywords" content="The Hymnal, Cameroon Hymnal, Book of Hymns, Songs, christian hymns, hymnal" />
                </Helmet>
                <div style={{ fontSize: "1.2rem" }}>
                  Welcome to The Hymnal
                  <br />
                  <br />
                  Use the &nbsp;
                  <BsSearch />
                  &nbsp; icon to search for hymns & use the &nbsp;
                  <BsX size="1.3rem" />
                  &nbsp; icon to close the search.
                  <br />
                  You can search for hymns by their hymn number & hymn title.
                  <br />
                  For any feedback or corrections, please reach out to divynemedia@gmail.com
                  <br />
                  Enjoy ;) <br />
                  <br />
                </div>
              </Route>
              <Route
                path="/hymn/:hymnNumber"
                render={(renderedProps) => {
                  return (
                    <HymnContent
                      {...renderedProps}
                      /*showHideSearchInterface={showHideSearchInterface}*/ hymnData={hymnData}
                      loadingSpinner={loadingSpinner}
                      exact
                    />
                  );
                }}></Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
        </div>
        <div className={`${slideInSearch ? style.bringInSearch : style.searchInputWrapper}`} style={{ flex: "1 1 50%" }}>
          <input
            className={style.searchInput}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            /*onFocus={() => showHideSearchInterface(true)}*/ type="text"
            placeholder="Search Hymn"
          />
          {/* <BsSearch style={{ position: "absolute", zIndex: 1, left: 0, padding: "15px", color: "rgb(204, 204, 204)" }} /> */}
          <SearchInterface
            setNumber={setNumber}
            openSI={openSearchInterface}
            hymnData={newHymnData}
            toggleSlideInSearch={toggleSlideInSearch}
            loadingSpinner={loadingSpinner}
          />
          {searchText.length > 0 ? (
            <BsXCircleFill
              onClick={(e) => cancelSearch()}
              style={{ position: "absolute", zIndex: 1, right: 0, padding: "9.5px 14px", fontSize: "1.8rem", color: "rgba(17, 17, 17, 0.8)" }}
            />
          ) : null}
        </div>
      </div>
    </HashRouter>
  );
}
