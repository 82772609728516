import React from 'react';
// import {BsSearch, BsXCircleFill} from "react-icons/bs";
import NavLinks from './NavLinks'
// import SearchInterface from "./SearchInterface"

export default function Header (props){

    // const [searchText, setSearchText] = useState("");
    // const [hymnNumber, setNumber] = useState("");

    // const cancelSearch = ()=>{
    //     props.showHideSearchInterface(false)
    //     setSearchText("")
    // }

        // const newHymnData = props.hymnData.filter(curr => (curr.hymnTitle.toLowerCase().includes(searchText.toLowerCase()) || curr.hymnNumber.toString().toLowerCase().includes(searchText.toLowerCase())))
        return (
            <div className="app-bar" style={{ background: "rgb(15, 15, 15)", padding: ".5rem", display: "flex", justifyContent: "center", borderRight: "1px solid #333333" }}>
                {/* <div style={{ flex: "1 1 50%", position: "relative" }}>
                    <input className={style.searchInput} value={searchText} onChange={e => setSearchText(e.target.value)} onFocus={() => props.showHideSearchInterface(true)} type="text" placeholder="Search Hymn" />
                    <BsSearch style={{ position: "absolute", zIndex: 1, left: 0, padding: "15px", color: "rgb(204, 204, 204)" }} />
                    <SearchInterface setNumber={setNumber} openSI={props.openSI} hymnData={newHymnData} />
                    {(searchText.length > 0)
                    ? 
                    <BsXCircleFill onClick={e => cancelSearch()} style={{ position: "absolute", zIndex: 1, right: 0, padding: "9px 5px", fontSize:"1.8rem", color:"rgba(17, 17, 17, 0.8)"}} />
                    :null
                    }
                </div> */}
                <div style={{ flex: "1 1 100%", display: "flex", justifyContent: "flex-start", alignItems: "center", fontWeight: "bold",marginLeft: "10px" }}><a href="/" title="" target="_self" style={{textDecoration: "none", color:"#fff", cursor: "pointer"}}>The Hymnal</a>{/*hymnNumber*/}</div>
                <NavLinks toggleSlideInSearch={props.toggleSlideInSearch} slideInSearch={props.slideInSearch}/>
            </div>
        );
}